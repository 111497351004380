<template>
  <b-card-code title="Modifier délégation">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de délégation </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form v-if="delegation">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Delegation"
              label-for="Delegation"
            >
              <validation-provider
                #default="{ errors }"
                name="Delegation"
                rules="required"
              >
                <b-form-input
                  v-model="delegation.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Delegation"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Gouvernoart"
              label-for="gouvernoart"
            >
              <validation-provider
                #default="{ errors }"
                name="gouvernoart"
                rules="required"
              >
                <v-select
                  v-model="delegation.governorate"
                  :clearable="false"
                  placeholder="gouvernorats"
                  label="name"
                  :options="governorates"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isDelegationValid }"
              :disabled="isLoading || !isDelegationValid"
              @click.prevent="editDelegation"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      id: '',
      required,
      delegation: [],
      governorates: [],
      isLoading: false,
    }
  },
  computed: {
    isDelegationValid() {
      return (
        this.delegation.name !== ''
        && this.delegation.governorate.id !== ''
      )
    },
  },
  created() {
    this.id = this.$route.params.id
    this.getDelegation()
    this.getGovernorates()
  },
  methods: {
    async getGovernorates() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/governorates/')
        this.governorates = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async getDelegation() {
      try {
        this.isLoading = true
        const { data } = await axios.get(`/api/delegations/detail/${this.id}`)
        this.delegation = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async editDelegation() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios.put(`/api/delegations/update/${this.id}/`,
              {
                name: this.delegation.name,
                governorate: this.delegation.governorate.id,
              })
            this.isLoading = false
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Delegation modifié avec succés')
            }, 1000)
            this.$router.push('/delegations/')
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
